import React, { useEffect, useState } from "react";
import { 
  FormFeedback, 
  FormGroup, 
  Input, 
  Label
} from "reactstrap";

export default function Cash(props) {
  const {
    setIsFirstStepValid,
    setPaymentPayload,
  } = props;
  
  const [collectorName, setCollectorName] = useState("");
  const [collectorID, setCollectorID] = useState("");
  const [collectorNumber, setcollectorNumber] = useState("");
  const [isInvalid, setIsInvalid] = useState(false);

  const handleCollectorNameChange = (e) => {
    const value = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;
    if (regex.test(value) || value === "") {
      setCollectorName(value);
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
  };

  // Update payment payload whenever any of the inputs change
  useEffect(() => {
    setPaymentPayload({
      collectorName,
      collectorID,
      collectorNumber,
    });
  }, [collectorName, collectorID, collectorNumber, setPaymentPayload]);

  useEffect(() => {
    setIsFirstStepValid(true);
  }, [setIsFirstStepValid]);

  return (
    <div>
      <FormGroup style={{ marginBottom: "10px" }}>
        <Label style={{ marginBottom: "10px" }} for="collector">Collecting Person Name</Label>
        <Input
          type="text"
          name="collector"
          id="collector"
          value={collectorName}
          onChange={handleCollectorNameChange}
          placeholder="Enter Collecting Person Name"
          invalid={isInvalid}
        />
        <FormFeedback>Please enter only letters</FormFeedback>
      </FormGroup>
      <FormGroup style={{ marginBottom: "10px" }}>
        <Label style={{ marginBottom: "10px" }} for="id">Collector ID</Label>
        <Input
          type="number"
          name="id"
          id="id"
          value={collectorID}
          onChange={(e) => setCollectorID(e.target.value)}
          placeholder="Enter Collector ID"
        />
      </FormGroup>
      <FormGroup style={{ marginBottom: "10px" }}>
        <Label style={{ marginBottom: "10px" }} for="number">Collector Contact Number</Label>
        <Input
          type="number"
          name="number"
          id="number"
          value={collectorNumber}
          onChange={(e) => setcollectorNumber(e.target.value)}
          placeholder="Enter Contact Number"
        />
      </FormGroup>
    </div>
  );
}