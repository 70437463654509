import logo from "../src/assets/images/brands/logo-lg.png";


export const clientName = "GGCC";
export const developedBy = "GGCC";
export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002";
export const apiUrl = process.env.REACT_APP_API_CP_DOMAIN || "http://localhost:3001";
export const companyName =  "GGCC";
export const companyWebsite = "#";
export const privacyPolicyLink = "https://exiniticsp.blob.core.windows.net/public/documents/AWEX_PRIVACY_POLICY.pdf";
export const clientAgreementLink = "https://exiniticsp.blob.core.windows.net/public/documents/AWEX_CLIENT_AGREEMENT.pdf";
export const sidebarLogo = logo;
export const smLogo = logo;
export const mainLogo = logo;
export const lightLogo = logo;
