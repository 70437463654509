import config from "config";
import whatsapp from "assets/img/payment-method/carlton-whatsapp.png";
export const allowedMethods = [
  {
    gateway: "WIRE_TRANSFER",
    name: "Wire Transfer",
    image: "/img/payment-method/bank_transfer.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
  },
  // {
  //   gateway: "OLX_FOREX",
  //   name: "Olx Forex",
  //   image: "/img/payment-method/olxforex.jpeg",
  //   allowed: ["fiatDeposit"],
  //   receipt: false,
  // },
  {
    gateway: "CRYPTO",
    name: "Crypto",
    image: "/img/payment-method/crypto.png",
    allowed: config.companyCryptoDeposit ? ["mt5Deposit", "fiatDeposit", "cryptoDeposit"] : [],
    receipt: true,
  },
  {
    gateway: "PERFECT_MONEY",
    name: "Perfect Money",
    image: "/img/payment-method/perfect-money.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
  },
  {
    gateway: "SKRILL",
    name: "Skrill",
    image: "/img/payment-method/skrill.png",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: true,
  },
  {
    gateway: "CASH",
    name: "Cash",
    image: "/img/payment-method/cash.jpeg",
    allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
    receipt: false,
  },
  // {
  //   gateway: "PAYER",
  //   name: "Payer",
  //   image: "/img/payment-method/payer.png",
  //   allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  //   receipt: true,
  // },
  // {
  //   gateway: "حواله بنكيه",
  //   name: "حواله بنكيه",
  //   image: "/img/payment-method/hawala_bank.png",
  //   allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  //   receipt: true,
  // },
  // {
  //   gateway: "LOCAL_DEPOSITOR",
  //   name: "LOCAL_DEPOSITOR",
  //   image: whatsapp,
  //   allowed: ["mt5Deposit", "fiatDeposit", "cryptoDeposit"],
  //   receipt: true,
  // },
];